import dynamic from 'next/dynamic';

const IconArrow = dynamic(() => import('./IconArrow'));
const IconFullscreen = dynamic(() => import('./IconFullscreen'));
const IconShareMail = dynamic(() => import('./IconShareMail'));
const SocialFB = dynamic(() => import('./SocialFB'));
const SocialTmpMessenger = dynamic(() => import('./SocialTmpMessenger'));

const icons = {
    IconArrow,
    IconFullscreen,
    IconShareMail,
    SocialFB,
    SocialTmpMessenger,
};

const Icon = ({ className, name }) => {
    const SVG = icons[name];
    if (!SVG) {
        throw Exception(`Icon '${name}' was not found.`);
    }
    return (
        <span className={className}>
            <SVG />
        </span>
    );
};

export default Icon;
